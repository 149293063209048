import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mayoraCero } from '@/_validations/validacionEspeciales';

export default () => {
    return {
        supplieId: { required },
        measureUnitId: { required },
        proveedorId: { required },
        Status: { required },
        fecha: { required },
        quantity: { required, minLength: minLength(1) },
        observation: { maxLength: maxLength(250) }
    }
}

